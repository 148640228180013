.quiz-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.quiz-container-main {
  width: 90%;
}

.quiz-inp-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.quiz-inp-field label {
  margin-bottom: 10px;
}

.quiz-inp-field input {
  padding: 6px 10px;
}

.quiz-inp-field button {
  width: 50%;
  margin-left: 0;
  padding: 6px 8px;
}

.quiz-container table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

.quiz-container table td,
th {
  padding: 10px;
  border: 1px solid black;
}

.quiz-add-ques-container {
  /* background-color: #a5c3f4; */
  margin: 20px 0;
  border-radius: 13px;
  padding: 30px;
  border: none;
  border-radius: 1rem;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}

.quiz-ques-field {
  width: 100%;
  margin-bottom: 5px;
}

.quiz-ques-field input {
  width: 100%;
  padding: 6px 8px;
}

.quiz-ques-ans {
  display: flex;
}

.quiz-ops {
  width: unset;
  display: flex;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding: 0 50px;
}

.quiz-table-wrong-ans {
  background-color: #ae1212;
}

.quiz-table-correct-ans {
  background-color: #036703;
}

.quiz-add-ques {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.quiz-add-ques button {
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 8px;
  /* border-radius: 23px; */
}

.button {
  margin-top: 1rem;
}


