.Uploaded {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.Uploaded-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.Uploaded-container {
    display: flex;
    /* margin-left: 20px; */
    justify-content: center;
    align-items: center;
}

.Uploaded input {
    width: 280px;
    height: 30px;
    font-size: 15px;
    text-align: center;
    border-radius: 25px;
}

.Uploaded-select {
    width: 150px;
    height: 40px;
    font-size: 15px;
    margin-left: 20px;
}

.Uploaded p {
    width: 40%;
}

.Uploaded-button button {
    margin: 30px 10px;
    padding: 10px 25px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
    font-size: 15px;
    border: 1px solid black;
}

.Uploaded-button button:hover {
    background-color: rgb(108, 108, 253);
}


.Apply-table {
    text-align: left;
    border: 2px solid black;
    border-collapse: collapse;
    width: 100%;
}

.Apply-table td {
    padding: 15px;
    border: 2px solid black;
}

.Apply-table th {
    background-color: #dcdcdc;
}

.Apply-table tr:nth-child(odd) {
    background-color: rgb(255, 255, 158);
}

.Apply-table tr:hover {
    background-color: #dcdcdc;
}