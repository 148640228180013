.Body {
  /* flex: 0.8; */
  /* padding-top: 20px; */
  overflow  : scroll;
  flex-grow: 1;

  background-color: #F8F5F4;
}

.body-content {
  min-height: 100%;
  height: 100%;
}

.module-for {
  font-size: 25px;
  text-align: center;
}


