.Tlibrary {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    flex-direction: column;
}

.Tlibrary-outer-container {
    width: 90%;
}

.Tlibrary-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.Tlibrary h3 {
    margin-bottom: 20px;
    font-size: 25px;
}

.Tlibrary-table {
    width: 45%;
}

.Tlibrary-table table {
    text-align: left;
    border: 2px solid black;
    border-collapse: collapse;
    flex: 0.5 1;
    width: 100%;
}

.Tlibrary-table table td {
    padding: 15px;
    border: 2px solid black;
}

.Tlibrary-table table th {
    background-color: #dcdcdc;
}

.Tlibrary-table table tr:nth-child(odd) {
    background-color: rgb(255, 255, 158);
}

.Tlibrary-table table tr:hover {
    background-color: #dcdcdc;
}

.button-library {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.button-library button {
    width: 30%;
    height: 35px;
    font-size: 15px;
    border: 2px solid black;
    box-shadow: 2px 2px 10px;
    margin-left: 0 !important;
}

.button-library button:hover {
    background-color: rgb(250, 146, 146);
}

.Tlib-selector {
    margin-bottom: 20px;
}

.Tlib-selector button {
    margin: 30px 10px;
    padding: 10px 25px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
    font-size: 15px;
    border: 1px solid black;
}

.Tlib-selector button:active {

    box-shadow: unset !important;

}