.Student {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 350px;
    border: 3px solid black;
    padding-top: 20px;
}

.Student select {
    width: 25%;
    height: 40px;
    margin: 15px 0px;
}

.Student button {
    width: 70%;
    height: 40px;
    border: 3px solid black;
    box-shadow: 2px 2px 10px;
    background-color: white;
}

.Student button:hover {
    background-color: rgb(255, 255, 158);
}

.Student h2 {
    display: flex;
}

.Student .progress {
    margin-top: 10px;
    margin-left: 20px;
    width: 30%;
}