.notice-board-box {
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 30px 10px;
    border: 1px solid black;
}

.notice-board-main {
    margin-top: 32px;
    max-height: 500px;
    overflow-y: scroll;
}

.notice-head {
    margin-bottom: 10px;
}

.notice-item-container span {
    font-size: 12px;
}