.deleteModal {
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
}

.delete-modal-content {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    width: 450px;
    height: max-content;
    padding: 20px 15px;
}


.delete-modal-bottom {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgba(0, 0, 0, 0.07); */
    padding: 20px;
}

.delete-modal-main-content {
    text-align: center;
    width: 80%;
}

.delete-modal-main-content h1 {
    color: #565555;
    margin-bottom: 10px;
}

.delete-modal-main-content p {
    color: #737171;
    margin-bottom: 15px;
}

.delete-modal-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.delete-modal-buttons button {
    padding: 15px 20px;
    background-color: #A8A8A8;
    border-radius: 6px;
    margin: 0 5px;
    color: white;
    font-size: 18px;
}

.delete-modal-buttons button:nth-child(2) {
    padding: 15px 20px;
    background-color: #FF0000;
    border-radius: 6px;
    margin: 0 5px;
    color: white;
    font-size: 18px;
}