/* .ChatMain {
  height: 75vh;
  background-color: lightgray;
  margin: 0 60px;
  border-radius: 8px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1rem;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
}

.logIn {
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .logIn input,
select {
  margin: 10px;
  border: 2px solid #0091ff;
  background-color: transparent;
  padding: 10px !important;
  font-size: 15px;
  color: white;
} */
/* 
.logIn select option {
  color: black;
}

.logIn input:focus {
  outline: none;
}

.logIn button {
  padding: 10px 20px;
  border: none;
  background-color: #0091ff;
  color: white;
  font-size: 16px;
}

.logIn button:hover {
  cursor: pointer;
  background-color: #037edc;
}

.chatContainer {
  width: 600px;
  width: 98%;
  height: 98%;
  border: 5px solid #0091ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatContainer .chat-room-info {
  padding: 10px;
  background-color: grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatContainer .chat-room-info button {
  padding: 5px 15px;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.chatContainer .chat-room-info button:active {
  box-shadow: unset !important;
}

.chatContainer .messages {
  flex: 95%;
  width: 100%;
  max-height: 80%;
  overflow-y: scroll;
  padding-left: 20px !important;
}

.chatContainer .messageInputs {
  flex: 5%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}

.chatContainer .messageInputs input {
  flex: 90%;
  height: calc(100% -5px);
  border: none;
  border-top: 5px solid #0091ff;
  padding: 5px;
  padding-left: 20px !important;
  font-size: 20px;
  border-radius: 6px;
}

.chatContainer .messageInputs button {
  flex: 10%;
  background-color: #0091ff;
  border: none;
  color: white;
  font-size: 18px;
  padding: 7.5px;
  border-radius: 6px;
}

.messageContainer {
  display: flex;
  width: calc(100% - 30px);
}

.messageContainer h1 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 17px;
}

#You {
  justify-content: flex-end;
}

#Other {
  justify-content: flex-start;
  margin-left: 10px;
}

#Other .messageIndividual {
  background-color: #5ff064;
  color: black;
}

.messageIndividual {
  min-width: 200px;
  max-width: 250px;
  height: fit-content;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #0091ff;
  opacity: 0.9;
  color: white;
  margin-right: 10px;
  margin-top: 20px;
}

.messageTime {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 10px;
}

.sendmessage {
  display: flex;
  margin: 1rem;
}
.sendmessage TextField {
  margin: 1rem;
} */

item-hover {
  background-color: #FFF;
}
item-hover:hover  {
  background-color: #a1a1a1;
}