.liveClasses-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
}

.liveClasses-container-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* align-items: center; */
}

.liveClass-create-container {
    height: 200px;
    width: 60%;
    border: 1px solid black;
    margin-bottom: 30px;
    background-color: #a1c0ee;
    padding: 15px 10px;
}

.liveClass-create-container-inp {
    margin-bottom: 10px;
}

.liveClass-create-container-inp input,
select {
    padding: 3px 8px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
}

.liveClass-create-container-inp label {
    margin-right: 10px;
}

.liveClass-create-btn {
    margin-left: 0;
    padding: 6px 15px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
    transition: all 250ms ease-in-out;
}

.liveClass-create-btn:hover {
    transition: all 250ms ease-in-out;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0);
}

input[type="checkbox"] {
    margin-right: 10px;
}

.class-link-table {
    width: 100%;
    /* max-width: 1300px; */
    text-align: left;
    border: 1px solid black;
    border-collapse: collapse;
}

.class-link-table thead tr {
    background-color: #dcdcdc !important;
}

.class-link-table td,
th {
    padding: 10px;
    border: 1px solid black;
}

.class-link-table tr:nth-child(odd) {
    background-color: #f7f7f7;
}

.class-link-table tr:hover {
    background-color: #dcdcdc;
}

.liveClasses-bottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 35px;
}

.class-join-btn {
    padding: 15px 40px;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    background-color: white;
    transition: all 250ms ease-in-out;
}

.class-join-btn:hover {
    box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.7);
    background-color: #f7f7f7;
}