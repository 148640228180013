.Notices_Student {
    display: flex;
    width: 100%;
    justify-content: center;
}

.notices-student-container {
    width: 85%;
}

.notices-student-btn {
    margin: 30px 0;
    padding: 6px 8px;
    width: 200px;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.notices-student-btn:active {
    box-shadow: unset !important;
}