
* {
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  /* background-color: #313348; */
  background-color:white;
  color: black;
  height: 100vh;
}

.main-container {
  display: flex;
  height: 100vh;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.MuiMenu-paper.MuiPopover-paper {
  max-height: 300px;
  max-width: 100px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 17.02px 17.02px 17.02px 17.02px;
  border: 1px solid #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}