.show-table-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.show-table-container-main {
  width: 80%;
}

.show-table-container-main button {
  background-color: white;
  padding: 10px 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
  margin-left: 0 !important;
}

.show-table-container-main button:active {

  box-shadow: unset !important;
}

.show-table-container .table {
  border-collapse: collapse;
}

.show-table-container .table thead {
  background-color: #ddd;
}

.show-table-container .table th,
tr td {
  border: 1px solid rgba(0, 0, 0, 0.65);
  padding: 15px;
}

.show-selector {
  width: 100%;
  display: flex;
  justify-content: center;
}

.show-selector button {
  background-color: #f7f7f7;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
  margin-left: 0;
  width: 100px;
  padding: 5px;
}