.center {
    text-align: center;
    padding: 10px 0;
}

.pagination {
    display: inline-block;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination a.active {
    background-color: rgb(255, 99, 132);
    color: white;
}

.pagination a:hover:not(.active) {
    background-color: #ddd;
}