.Header {
  padding: 0px 8px;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #00000026;
}

.navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* color: #1981ee; */
}

.navbar a {
  text-decoration: none;
  /* height: 40px; */
  /* color: #1981ee; */
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
}

.navbar-left a {
  display: flex;
  width: 100%;
  text-decoration: none;
  height: 40px;
}

.logo {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.logo img {
  width: 40px;
}

.navbar-right {
  display: flex;
  justify-content: end;
  align-items: center;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  align-items: center;
}

.navbar button {
  padding: 6px 10px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: #1981ee;
}

.nav-login-btn {
  background-color: #1981ee !important;
  color: white !important;
  border-radius: 6px;
}

.dropdown-toggle::after {
  display: none !important; 
}
