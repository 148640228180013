.complete-radio select {
    width: 200px;
    height: 40px;
    border: 2px solid black;
    margin: 20px;
}

.fee-admin-button {
    border: 2px solid black;
    width: 150px;
    height: 35px;
    border-radius: 20px;
}

.fee-admin-button:hover {
    background-color: antiquewhite;
}