/* Main Component */

.Notices_Teacher {
    display: flex;
    width: 100%;
    justify-content: center;
}

.notices-teacher-container {
    width: 85%;
}

.notices-teacher-btn {
    margin: 30px 0;
    padding: 6px 8px;
    width: 200px;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
    transition: all 250ms ease-in-out;
}

.notices-teacher-btn:hover {

    transition: all 250ms ease-in-out;
    box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.55);

}


.notice-options {
    margin-bottom: 10px;
}

.notice-options button {
    padding: 3px;
}

.notice-options select {
    margin-right: 10px;
}





/* Create Component */

.notices-teacher-create-container {
    display: flex;
    justify-content: center;
    padding: 0 30px;
    align-items: center;
}

.notices-teacher-create-container-main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.notices-teacher-create-box {
    width: 100%;
    height: 100%;
    min-height: 400px;
    padding: 30px 10px;
    border: 1px solid black;
    background-color: #DCDCDC;
}

.notices-teacher-create-main {
    margin-top: 32px;
}

.notices-teacher-create-btn {
    margin: 30px 0;
    padding: 6px 8px;
    width: 200px;
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
    transition: all 250ms ease-in-out;
}

.notices-teacher-create-btn:hover {

    transition: all 250ms ease-in-out;
    box-shadow: 1px 1px 3px rgba(255, 255, 255, 0.55);

}

.notice-teacher-create-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.notice-teacher-create-inp {
    display: flex;
    align-items: center;
    width: 45%;
    min-width: 250px;
    margin: 20px 0;
}

.notice-teacher-create-inp label {
    /* margin-right: 25px; */
    width: 100%;
}

.notice-teacher-create-inp input,
textarea,
select {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
}

.notice-teacher-create-inp input,
textarea {
    width: 100%;
    padding: 4px 10px;
}

.notice-teacher-create-inp select {
    padding: 4px 10px;
}

#notice-teacher-create-file {
    padding: 0;
}

.notice-teacher-create-submit {
    padding: 0;
}

.notice-teacher-create-submit button {
    padding: 7px 15px;
}

.notice-teacher-create-submit button:nth-child(1) {
    background-color: #fffe01;
}