.go-head {
    margin: 0;
}

.go-head button {
    width: 15%;
    height: 40px;
    font-size: large;
    box-shadow: 2px 2px 10px;
}

.go-head button:hover {
    background-color: rgb(104, 255, 155);
}