* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.add-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-details div{
    padding: 0.5rem;
}
.fees-input {
  outline: none;
  border:0;
}
