.sidebar {
    flex: 0.2;
    height: 90vh;
    overflow-y: auto;
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    border-right: 1px solid #00000026;
    min-width: 150px;
    max-width: 250px;
    /* max-width: 16%; */
    transition: all 250ms;
    justify-content: space-between;
}

.sidebar-collapse {
    flex: 0 !important;
    min-width: 20px;
}

.sidebar-options {
    height: 95%;
    overflow: auto;
}

::-webkit-scrollbar {
    width: 1;
}

.sidebar-collapse {
    min-width: unset !important;
    flex: unset !important;
    width: 36px !important;
    /* transition: all 250ms; */
}

.sidebar-item {
    padding: 12px 12px;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar-item:hover {
    background-color: rgba(0, 0, 0, .1);
}

.sidebar-title {
    display: flex;
    /* font-size: 1.2em; */
    justify-content: space-between;
    align-items: center;
}

.sidebar-title span {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.sidebar-title span i {
    display: inline-block;
    width: 1.5em;
}

.sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform .3s;
}

.sidebar-item.open>.sidebar-title .toggle-btn {
    transform: rotate(180deg);
}

.sidebar-content {
    padding-top: .25em;
    height: 0;
    overflow: hidden;
}

.sidebar-item.open>.sidebar-content {
    height: auto;
}

.sidebar-item.plain {
    text-decoration: none;
    color: black;
}

.sidebar-item.plain:hover {
    text-decoration: underline;
}

.sidebar-item.plain i {
    display: inline-block;
    width: 1.7em;
}

.sidebar-option-toggle-icon {
    transition: all 250ms;
}

.sidebar-option-toggle-icon-open {
    transform: rotate(180deg);
}

.sidebar-collapser {
    display: flex;
    justify-content: flex-end;
    padding: 12px 12px;
}

.sidebar-collapser-icon {
    cursor: pointer;
    transition: all 250ms;
    transform: rotate(180deg);
}

.sidebar-collapser-icon-toggle {
    transform: rotate(0deg);
}