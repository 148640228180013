.TPrev-Attendance-Container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.TPrev-Attendance-Container-main {
    width: 90%;
}


.tprev-attend-top {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    /* align-items: center; */
}

.tprev-attend-top select {
    margin-right: 20px;
}

.tprev-attend-bottom {
    margin-top: 40px;
}

.tprev-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.tprev-info table {
    flex: 1;
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
}

.tprev-info table td,
th {
    border: 1px solid black;
    padding: 10px;
    text-align: center;
}

.tprev-info table thead tr {
    background-color: #dcdcdc !important;
}

.tprev-info table tr:nth-child(odd) {
    background-color: #f7f7f7;
}


.tprev-info-calendar {
    flex: 0.45;
}

.TPrev-Attendance-Container button {
    padding: 2.5px 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    background-color: white;
}

.TPrev-Attendance-Container button:active {
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0);
}