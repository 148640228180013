.Login input,
button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

.Login {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem;
}
/* .Login::before {
  content: "";
  position: absolute;
  top: 6.5vh;
  right: 0px;
  bottom: 0px;
  left: 0px;

  Specify the background image to be used
  background-image: url(
'https://images.wallpapersden.com/image/download/minimal-abstract-2021-art_bGxla2aUmZqaraWkpJRmbmdlrWZlbWU.jpg');
  background-size: cover;

  Specify the background image
  opacity: 0.7;
} */
.Side_login_body {
  display: flex;
  flex-direction: column;
  flex: 0.3;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 72vh;
  border-radius: 2rem;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 4%), 0 8px 16px rgb(0 0 0 / 4%);
  font-weight: bolder;
}
.Side_login_body img {
  height: 400px;
  width: 400px;
  object-fit: contain;
}

.login-container {
  flex: 0.5;
  padding: 1rem;
}

.signin {
  color: black;
  font-size: medium;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bolder;
}

.Login form {
  display: block;
  position: relative;
  min-width: 320px;
}

/* .Login form:after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: 1;
  background-image: linear-gradient(to bottom right, #ffce00, #fe4880);
} */

.alert {
  margin: 1rem;
}

.Login form .form-inner {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px;
  z-index: 2;
}

.Login form .form-inner h2 {
  color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px;
}

.Login form .form-inner .form-group {
  display: block;
  width: 300;
  margin: 1rem;
  display: flex;
  flex-direction: column;
}

.Login form .form-inner .form-group label {
  display: block;
  color: #666;
  font-size: 15px;
  margin-bottom: 5px;
  transition: 1.4s;
}

.Login form .form-inner .form-group:focus-within label {
  color: #fe4880;
}

/* .Login form .form-inner .form-group input {
  display: block;
  padding: 10px 15px;
  background-color: #f8f8f8;
  border-radius: 8px;
  transition: 1.4s;
} */

.Login form .form-inner .form-group input:focus-within {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
}

.Login form .form-inner input[type="submit"],
.welcome button {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #ffce00, #ffce00 50%, #fe4880);
  background-size: 200%;
  background-position: 0%;
  transition: 1.4s;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.Login form .form-inner input[type="submit"]:hover,
.welcome button:hover {
  background-position: 100% 0%;
}

.Login .welcome {
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  padding: 15px 0px;
  margin: 1rem;
}

.Login .welcome h2 {
  /* color: #888;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 30px; */
  color: black;
  font-size: larger;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-weight: bolder;
}
.Login .welcome p {
  font-size: small;
}

.Login .welcome h2 span {
  color: #fe4880;
  font-weight: 700;
}

.container-forget-password {
  width: 100%;
  height: 90vh;
  /* background-color: #fe4880; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.forget-password {
  width: 50%;
}
.forgetDetails {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.forget-form {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}
.h {
  margin: 0.5rem;
  color: #637381;
  line-height: 1.5;
  font-family: Public Sans, sans-serif;
}
.h1 {
  color: black;
  margin: 0.5rem;
}

/* verify otp */
.verify-otp {
  display: flex;

  height: 88vh;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}

.verify-container {
  width: 50%;

  display: flex;
  flex-direction: column;
}

.verify-text h1 {
  margin: 1rem;
}
.para {
  margin: 1rem;
}
.timer-countdown {
  display: flex;
  align-items: center;
  margin: 1rem;
}
/* form {
  display: flex;
  flex-direction: column;
} */

.verify-button {
  background-color: rgb(0, 171, 85);
}

.resend-code {
  margin: 1rem;
}

.verify-form {
  display: flex;
  align-items: center;
}
