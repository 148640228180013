.Treports {
    display: flex;
    flex-direction: column;
}

.Treports button {
    margin-left: 20px;
    margin-bottom: 20px;
    height: 40px;
    width: 25%;
    font-size: large;
    font-weight: 500;
    border: 3px solid black;
    background-color: white;
    box-shadow: 2px 2px 10px;
}

.Treports button:hover {
    background-color: rgb(255, 255, 158);
}


/*--------------- class Evalution -------------- */

.Evalution {
    display: flex;
    flex-direction: column;
}

.Evalution select {
    width: 25%;
    margin-left: 10px;
    height: 40px;
    margin-bottom: 10px;
}

.div-progress {
    height: 250px;
    margin-left: 10px;
    border: 3px solid black;
}

.Evalution button {
    width: 70%;
    height: 40px;
    border: 3px solid black;
    box-shadow: 2px 2px 10px;
    background-color: white;
}

.Evalution button:hover {
    background-color: rgb(255, 255, 158);
}

.Evalution h4,
h2 {
    margin-bottom: 15px;
}

.Evalution .div-progress {
    padding-top: 50px;
    width: 80%;
}

.report-overall-perf {
    display: flex;
    margin: 10px 0;
    justify-content: space-between;
    min-width: 300px;
}