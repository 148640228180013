.Areports {
    display: flex;
    flex-direction: column;
}

.Areports button {
    margin-left: 20px;
    margin-bottom: 20px;
    height: 40px;
    width: 25%;
    font-size: large;
    font-weight: 500;
    border: 3px solid black;
    background-color: white;
    box-shadow: 2px 2px 10px;
}

.Areports button:hover {
    background-color: rgb(255, 255, 158);
}