.course-card {
    border-radius: 10px;
    background-color: #fff;
    max-width: 300px;
    min-width: 100%;
}

.course-card:hover {
    background-color: #ffffff;
    cursor: pointer;
    transform: scale(1.1);
    z-index: 1000;
    transition: all 200ms ease-in-out;
}

.paragraph {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.paragraph::-webkit-scrollbar {
    display: none;
}