.addContent {
    display: flex;
    width: 100%;
    justify-content: center;
}

.addContent-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    min-height: 200px;
    background-color: #92db92cc;
    border-radius: 0.5em;
    padding: 20px;
}

.addContainer-title {
    font-size: 1.2em;
    width: 100%;
    text-align: center;
}

.addContainer-inputs {
    width: 30%;
}

.addContainer-inp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.addContainer-inp input {
    padding: 4px;
}

.addContainer-inputs button {
    background-color: white;
    padding: 8px 16px;
    margin-left: 0 !important;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.addContainer-inputs button:active {
    box-shadow: unset !important;
}